<template>
  <div v-if="isPortrait">
    <div class="full-screen">
      <span class="text-portrait">Putar layar HP anda ke mode Landscape</span>
    </div>
  </div>
  <div v-if="!isPortrait">
    <div
      v-if="showSplashScreen"
      @click="enterMainView"
      class="full-screen"
      id="splash"
    >
      <div class="splash-image">
        <!-- <img :class="leftTextLogo" src="../assets/images/logo-fun.png" /> -->
        <img :class="classSplashLogo" src="../assets/images/logo-mid.webp" />
        <!-- <img :class="rightTextLogo" src="../assets/images/logo-liga.png" /> -->
        <!--img :class="classSplashLogo" src="../assets/images/logo-fl.png" /-->
      </div>
      <audio id="audioLoop" autoplay>
        <source src="../assets/audio/entrance.mp3" type="audio/mp3" />
      </audio>
      <div :class="classSplashCloud">
        <div
          v-for="(cloud, index) in splashItems"
          :key="index"
          :class="cloud.class"
        >
          <img
            v-for="(cld, idx) in cloud.cloudImg"
            :key="idx"
            :class="cld.class"
            :src="require(`../assets/images/awan/` + cld.src)"
            :alt="cld.alt"
          />
        </div>
      </div>
    </div>
    <div v-if="!showSplashScreen" id="login" class="full-screen">
      <div
        v-for="(cloud, index) in splashItems"
        :key="index"
        :class="cloud.class"
      >
        <img
          v-for="(cld, idx) in cloud.cloudImg"
          :key="idx"
          :class="cld.class"
          :src="require(`../assets/images/awan/` + cld.src)"
          :alt="cld.alt"
        />
      </div>
      <div class="login-page">
        <div v-if="showTutorial" class="full-screen modal-v">
          <div :class="videoFrame">
            <div class="vid-panduan">
              <img src="../assets/images/video/panduan.webp" />
            </div>
            <div class="vid-play">
              <!--img src="../assets/images/video/button playx.png" /-->
            </div>
            <div class="vid-skip" @click="skipTutorial">
              <img src="../assets/images/video/buttonx.webp" />
            </div>
          </div>
        </div>
        <div v-if="!showTutorial" :class="loginFrame">
          <img class="logo-fl-login" src="../assets/images/logo-fl.webp" />
          <div class="login-group" v-if="loginForm">
            <form @submit.prevent="login">
              <div class="text-login">MASUK</div>
              <div class="octagon-input-container">
                <input
                  type="text"
                  v-model="username"
                  class="octagon-input"
                  name="username"
                  id="username"
                  @keyup.enter="login"
                  required
                  placeholder="Username"
                />
              </div>
              <div class="octagon-input-container">
                <input
                  type="password"
                  v-model="password"
                  class="octagon-input"
                  name="password"
                  id="password"
                  @keyup.enter="login"
                  required
                  placeholder="Password"
                />
              </div>
              <div class="text-setuju-container">
                <div class="checkbox-setuju text-setuju text-montserrat">
                  <input
                    type="checkbox"
                    v-model="setuju"
                    name="setuju"
                    id="setuju"
                    required
                  />
                  Saya memahami dan menyetujui syarat dan ketentuan yang berlaku.
                </div>
              </div>
            </form>
          </div>
          <div class="login-group reset" v-if="!loginForm">
            <form @submit.prevent="reset">
              <div class="text-login">ATUR ULANG</div>
              <div class="octagon-input-container">
                <input
                  type="password"
                  class="octagon-input"
                  name="dpassword"
                  v-model="dpassword"
                  @input="onlyNumberKey"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  maxlength="6"
                  id="dpassword"
                  placeholder="MASUKKAN 6 DIGIT ANGKA"
                />
              </div>
            </form>
          </div>
          <img
            v-if="loginForm && !isLoading"
            @click="login"
            class="button-login"
            src="../assets/images/button_login.webp"
            alt="Login"
          />
          <div
            v-if="loginForm && isLoading"
            class="button-login-loading"
          >
            <span>.....</span>
          </div>
          <img
            v-if="!loginForm"
            @click="reset"
            class="button-login"
            src="../assets/images/button_next.png"
          />
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>

export default {
  name: "SplashScreen",
  data() {
    return {
      dataPengguna: [
        {
          username: "1010015",
          pass: "123456",
          nama: "MARGO MULYO MEGAH",
          reset: false,
          game: "hunter",
          rank: "master",
        },
        {
          username: "1010023",
          pass: "123456",
          nama: "KAWI INDAH JAYA MOTOR",
          reset: true,
          game: "speed",
          rank: "grandmaster",
        },
        {
          username: "1010031",
          pass: "123456",
          nama: "MANDALA KEKAR ABADI",
          reset: false,
          game: "hunter",
          rank: "epic",
        },
        {
          username: "1010038",
          pass: "123456",
          nama: "LANCAR MOTOR SUKSES MANDIRI",
          reset: true,
          game: "speed",
          rank: "legendary",
        },
        {
          username: "1010040",
          pass: "123456",
          nama: "JAYA PERKASA ABADI",
          reset: false,
          game: "speed",
          rank: "master",
        },
        {
          username: "1010053",
          pass: "123456",
          nama: "JAYA UTAMA MOTOR",
          reset: true,
          game: "speed",
          rank: "grandmaster",
        },
        {
          username: "1010167",
          pass: "123456",
          nama: "MANDALA JAYA ABADI",
          reset: false,
          game: "hunter",
          rank: "epic",
        },
        {
          username: "1020001",
          pass: "123456",
          nama: "CIKANDE MOTOR",
          reset: true,
          game: "hunter",
          rank: "legendary",
        },
        {
          username: "1020006",
          pass: "123456",
          nama: "PRIMADELTA STARLESTARI",
          reset: false,
          game: "hunter",
          rank: "master",
        },
        {
          username: "1020023",
          pass: "123456",
          nama: "SENDANG BERSAMA",
          reset: true,
          game: "speed",
          rank: "grandmaster",
        },
        {
          username: "1020055",
          pass: "123456",
          nama: "FAJAR MANDIRI",
          reset: false,
          game: "hunter",
          rank: "epic",
        },
        {
          username: "1020061",
          pass: "123456",
          nama: "ANUGERAH HASTA MANDIRI",
          reset: true,
          game: "speed",
          rank: "legendary",
        },
        {
          username: "1030002",
          pass: "123456",
          nama: "GAMMA EKA SENTOSA",
          reset: false,
          game: "runner",
          rank: "master",
        },
        {
          username: "1030005",
          pass: "123456",
          nama: "JATAKE",
          reset: true,
          game: "runner",
          rank: "grandmaster",
        },
        {
          username: "1030010",
          pass: "123456",
          nama: "PRIMA MOTOR",
          reset: false,
          game: "runner",
          rank: "epic",
        },
        {
          username: "1030016",
          pass: "123456",
          nama: "TUNAS SAKTI MOTOR",
          reset: true,
          game: "runner",
          rank: "legendary",
        },
        {
          username: "1030017",
          pass: "123456",
          nama: "TELUK NAGA MOTOR",
          reset: false,
          game: "runner",
          rank: "master",
        },
        {
          username: "1030044",
          pass: "123456",
          nama: "NUGRAHA CIPTA PERKASA",
          reset: true,
          game: "runner",
          rank: "grandmaster",
        },
        {
          username: "1030093",
          pass: "123456",
          nama: "USAHA CIPTA MANDIRI",
          reset: false,
          game: "runner",
          rank: "epic",
        },
        {
          username: "speed",
          pass: "123456",
          nama: "BARATHRUM",
          reset: true,
          game: "speed",
          rank: "epic",
        },
        {
          username: "hunter",
          pass: "123456",
          nama: "RIKIMARU",
          reset: true,
          game: "hunter",
          rank: "epic",
        },
        {
          username: "runner",
          pass: "123456",
          nama: "WEAVER",
          reset: true,
          game: "runner",
          rank: "epic",
        },
      ],
      message: "",
      showSplashScreen: true,
      showTutorial: false,
      loginForm: true,
      splashItems: [
        {
          class: "splash-cloud-left",
          cloudImg: [
            {
              class: "cloud-left-1",
              src: `awan.png`,
              alt: "cloud",
            },
          ],
        },
        {
          class: "splash-cloud-right",
          cloudImg: [
            {
              class: "cloud-right-1",
              src: `awan.png`,
              alt: "cloud",
            },
          ],
        },
      ],
      leftTextLogo: "left-logo",
      rightTextLogo: "right-logo",
      classSplashLogo: "splash-logo",
      classSplashCloud: "splash-cloud",
      loginFrame: "login-frame",
      videoFrame: "video-frame",
      username: "",
      password: "",
      dpassword: "",
      setuju: true,
      isLoggedIn: false,
      isPortrait: window.innerWidth < 576,
      isLoading: false,
    };
  },
  methods: {
    loginClick() {
      this.loginForm = !this.loginForm;
    },
    enterMainView() {
      this.classSplashLogo = "splash-logo scale-fl-center-r";
      this.leftTextLogo = "left-logo scale-in-right-r";
      this.rightTextLogo = "right-logo scale-in-left-r";
      this.splashItems[0].class = "splash-cloud-left slide-left-r";
      this.splashItems[1].class = "splash-cloud-right slide-right-r";
      setTimeout(() => {
        this.showSplashScreen = false;
        this.splashItems[0].class = "splash-cloud-left slide-left";
        this.splashItems[1].class = "splash-cloud-right slide-right";
        // langsung login
        //this.loginFrame = "video-frame scale-up-center";
        this.loginFrame = "login-frame scale-up-center";
      }, 1000);
    },
    skipTutorial() {
      this.videoFrame = "video-frame scale-up-center-r";
      this.splashItems[0].class = "splash-cloud-left slide-left-r";
      this.splashItems[1].class = "splash-cloud-right slide-right-r";
      setTimeout(() => {
        this.splashItems[0].class = "splash-cloud-left";
        this.splashItems[1].class = "splash-cloud-right";
        this.showTutorial = false;
        this.loginFrame = "login-frame scale-up-center";
        this.splashItems[0].class = "splash-cloud-left slide-left";
        this.splashItems[1].class = "splash-cloud-right slide-right";
      }, 2000);
    },
    async login() {
      if (this.setuju && this.username != "" && this.password != "") {
        try {
          this.isLoading = true;
          const response = await fetch(
            "https://admin.fl-fifastra.com/api/login",
            {
              method: "POST",
              body: JSON.stringify({
                username: this.username,
                password: this.password,
              }),
            }
          );
          if (response.ok) {
            const data = await response.json();
            if (data.code == "200") {
              if (data.data.reset == 1) {
                this.loginForm = false;
              } else {
                const userData = {
                  username: data.data.username,
                  token: data.data.token,
                  nama: data.data.nama,
                  games: data.data.games,
                  rank: "master",
                };
                this.loginFrame = "login-frame scale-up-center-r";
                this.splashItems[0].class = "splash-cloud-left slide-left-r";
                this.splashItems[1].class = "splash-cloud-right slide-right-r";
                setTimeout(() => {
                  this.splashItems[0].class = "splash-cloud-left";
                  this.splashItems[1].class = "splash-cloud-right";
                  this.$store.dispatch("login", userData);
                  this.$router.push("/dashboard");
                }, 2000);
              }
            } else {
              alert(data.message);
            }
          } else {
            console.error("Gagal terhubung ke API Server");
          }
        } catch (error) {
          console.error("Gagal mengambil data :", error);
        } finally {
          this.isLoading = false;
        }
      }
      /*
            const pengguna = this.dataPengguna.find(
              (element) => element.username === this.username.toLowerCase()
            );
            if (pengguna && this.setuju == true) {
              if (pengguna.pass === this.password.toLowerCase()) {
                if (pengguna.reset) {
                  this.isLoggedIn = true;
                  this.loginForm = false;
                } else {
                  // LOGIN JIKA SUDAH RESET PASS
                  this.isLoggedIn = true;
                  this.loginFrame = "login-frame scale-up-center-r";
                  this.splashItems[0].class = "splash-cloud-left slide-left-r";
                  this.splashItems[1].class = "splash-cloud-right slide-right-r";
                  setTimeout(() => {
                    this.splashItems[0].class = "splash-cloud-left";
                    this.splashItems[1].class = "splash-cloud-right";
                    //this.$store.dispatch("login");
                    const userData = {
                      username: pengguna.username,
                      nama: pengguna.nama,
                      game: pengguna.game,
                      rank: pengguna.rank,
                    };
                    this.$store.dispatch("login", userData);
                    //localStorage.setItem('isAuthenticated', true);
                    //localStorage.setItem('userdata',JSON.stringify(userData));
                    this.$router.push("/dashboard");
                  }, 2000);
                }
              }
            } else {
              if (this.setuju == true) {
                alert("USER / PASSWORD SALAH.\nMohon dicek kembali");
              } else {
                alert("Anda harus setuju");
              }
            }
            /* LAMA
            if (
              user.includes(this.username.toLowerCase()) &&
              this.username.toLowerCase() === this.password.toLowerCase() &&
              this.setuju == true
            ) {
              // LOGIN JIKA SUDAH RESET PASS
              this.isLoggedIn = true;
              this.loginFrame = "login-frame scale-up-center-r";
              this.splashItems[0].class = "splash-cloud-left slide-left-r";
              this.splashItems[1].class = "splash-cloud-right slide-right-r";
              setTimeout(() => {
                this.splashItems[0].class = "splash-cloud-left";
                this.splashItems[1].class = "splash-cloud-right";
                //this.$store.dispatch("login");
                const userData = { username: this.username, rank: "EPIC" };
                this.$store.dispatch("login", userData);
                //localStorage.setItem('isAuthenticated', true);
                //localStorage.setItem('userdata',JSON.stringify(userData));
                this.$router.push("/dashboard");
              }, 2000);
            } else if (
              user2.includes(this.username.toLowerCase()) &&
              this.username === this.password.toLowerCase() &&
              this.setuju == true
            ) {
              // LOGIN JIKA USER PERLU RESET PASS
              this.isLoggedIn = true;
              this.loginForm = false;
            } else {
              if (this.setuju == true) {
                alert("USER / PASSWORD SALAH.\nMohon dicek kembali");
              } else {
                alert("Anda harus setuju");
              }
            }
            */
    },
    async reset() {
      if (this.dpassword.length == 6) {
        try {
          const response = await fetch(
            "https://admin.fl-fifastra.com/api/reset",
            {
              method: "POST",
              body: JSON.stringify({
                username: this.username,
                password: this.password,
                passwordbaru: this.dpassword,
              }),
            }
          );
          if (response.ok) {
            const data = await response.json();
            if (data.code == "200") {
              const userData = {
                username: data.data.username,
                token: data.data.token,
                nama: data.data.nama,
                games: data.data.games,
                rank: "master",
              };
              this.loginFrame = "login-frame scale-up-center-r";
              this.splashItems[0].class = "splash-cloud-left slide-left-r";
              this.splashItems[1].class = "splash-cloud-right slide-right-r";
              setTimeout(() => {
                this.splashItems[0].class = "splash-cloud-left";
                this.splashItems[1].class = "splash-cloud-right";
                this.$store.dispatch("login", userData);
                this.$router.push("/dashboard");
              }, 2000);
            } else {
              alert(data.message);
            }
          } else {
            console.error("Gagal terhubung ke API Server");
          }
        } catch (error) {
          console.error("Gagal mengambil data :", error);
        }
      }
    },
    onlyNumberKey() {
      this.dpassword = this.dpassword.replace(/\D/g, "");
      // Memotong nilai menjadi maksimal 6 karakter
      if (this.dpassword.length > 6) {
        this.dpassword = this.dpassword.slice(0, 6);
      }
    },
    resizeWindow() {
      this.isPortrait = window.innerWidth < 576;
    },
  },
  created() {
    window.addEventListener("resize", this.resizeWindow);
  },
  unmount() {
    window.removeEventListener("resize", this.resizeWindow);
  },
  mounted() {
    document.title = "FUNTASTIC LEAGUE";
    /* FORCE FULLSCREEN
        var viewFullScreen = document.getElementById("app");
        if (viewFullScreen) {
          viewFullScreen.addEventListener("click", function () {
            var docElm = document.documentElement;
            if (docElm.requestFullscreen) {
              docElm.requestFullscreen();
            } else if (docElm.msRequestFullscreen) {
              docElm.msRequestFullscreen();
            } else if (docElm.mozRequestFullScreen) {
              docElm.mozRequestFullScreen();
            } else if (docElm.webkitRequestFullScreen) {
              docElm.webkitRequestFullScreen();
            }
          });
        }
        */
    const isAuthenticated = this.$store.getters.isAuthenticated;
    //const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (isAuthenticated) {
      // Jika sudah login, redirect to Main Page
      this.$router.push("/dashboard");
    }
    this.$store.commit("updateAppClass", "splash-china-style");
    this.splashItems[0].class = "splash-cloud-left slide-left";
    this.splashItems[1].class = "splash-cloud-right slide-right";
    this.classSplashLogo = "splash-logo scale-fl-center";
    this.leftTextLogo = "left-logo scale-in-right";
    this.rightTextLogo = "right-logo scale-in-left";
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "../assets/css/main.css";
</style>
