<template>
  <div v-if="isPortrait">
    <div class="full-screen">
      <span class="text-portrait">Putar layar HP anda ke mode Landscape</span>
    </div>
  </div>

  <div v-if="!isPortrait">
    <Transition name="fade">
      <div v-if="showWelcomeScreen" @click="enterMainView" class="full-screen" id="splash">
        <div class="splash-main">
          <img :class="classSplashLogo" :src="require(`../assets/images/` + srcSplashLogo)" />
        </div>
        <div v-for="(cloud, index) in splashItems" :key="index" :class="cloud.class">
          <img v-for="(cld, idx) in cloud.cloudImg" :key="idx" :class="cld.class"
            :src="require(`../assets/images/awan/` + cld.src)" :alt="cld.alt" />
        </div>
      </div>
    </Transition>
    <Transition name="fade">
      <div v-if="!showWelcomeScreen" id="mainScreen">
        <div v-if="showWelcomeText" class="full-screen flex-center" @click="enterDashboard">
          <div class="text-erg text-welcome scale-in-welcome">
            <img :src="require(`../assets/images/` + srcSplashWelcome)" />
          </div>
        </div>
        <div v-if="!showWelcomeText" class="full-screen">
          <Transition name="fade">
            <div v-if="isPromosiAwal" class="full-screen modal-v">
              <img class="promosi-container" src="../assets/images/promo-fl-april.jpg" @click="hidePromosiAwal" />
              <div class="close-modal" @click="hidePromosiAwal">
                <img src="../assets/images/close_icon.png" />
              </div>
            </div>
          </Transition>
          <Transition name="fade">
            <div v-if="isGimmick" class="full-screen modal-v">
              <div :class="classFrame">
                <div class="vid-panduan">
                  <img src="../assets/images/video/panduan.webp" />
                </div>
                <video class="vid-video" preload="auto" autoplay controls>
                  <source :src="require(`../assets/video/gimmick/` + videoGimmick)" type="video/mp4" />
                </video>
                <div class="vid-skip" @click="hideGimmick">
                  <img src="../assets/images/video/buttonx.webp" />
                </div>
              </div>
            </div>
          </Transition>
          <Transition name="fade">
            <div v-if="isTutorialOne" class="full-screen modal-v">
              <div :class="classFrame">
                <div class="vid-panduan">
                  <img src="../assets/images/video/panduan.webp" />
                </div>
                <video class="vid-video" preload="auto" autoplay controls>
                  <source :src="require(`../assets/video/` + videoTutorialOne)" type="video/mp4" />
                </video>
                <div class="vid-skip" @click="hideTutorialOne">
                  <img src="../assets/images/video/buttonx.webp" />
                </div>
              </div>
            </div>
          </Transition>
          <Transition name="fade">
            <div v-if="isTutorialTwo" class="full-screen modal-v">
              <div :class="classFrame">
                <div class="vid-panduan">
                  <img src="../assets/images/video/panduan.webp" />
                </div>
                <video class="vid-video" preload="auto" autoplay controls>
                  <source :src="require(`../assets/video/` + videoTutorialTwo)" type="video/mp4" />
                </video>
                <div class="vid-skip" @click="hideTutorialTwo">
                  <img src="../assets/images/video/buttonx.webp" />
                </div>
              </div>
            </div>
          </Transition>
          <Transition name="fade">
            <div v-if="showAudio">
              <audio id="audioLoop" autoplay loop :volume="audioVolume">
                <source :src="require(`../assets/audio/` + srcBacksound)" type="audio/mp3" />
              </audio>
            </div>
          </Transition>
          <div v-if="!isComingSoon">
            <div class="greeting-frame">
              <div v-if="showTypeWriter" :class="`text-profil profil-text-style ${classWelcomeText}`">
                <span>{{ textWriterAtas }}</span>
                <span>{{ textWriterBawah }}</span>
              </div>
            </div>
            <div class="navbar-container">
              <div class="navbar-item-container">
                <div :class="`${classEntertainment} ${showEntertainment && 'active'}`"
                  @click="handleNavBar('ENTERTAINMENT')"></div>
                <div :class="`${classPerformance} ${showPerformance && 'active'}`" @click="handleNavBar('PERFORMANCE')">
                </div>
                <div :class="`${classPromo} ${showPromo && 'active'}`" @click="handleNavBar('PROMO')"></div>
                <div :class="`${classFaq} ${showFaq && 'active'}`" @click="handleNavBar('FAQ')"></div>
              </div>
              <div @click="showChart" :style="{ visibility: showPerformance ? 'visible' : 'hidden' }"
                class="navbar-progress-bar">
                <img class="progress-bar-background" :src="computedSrcProgressBarBorder" />
                <img :style="{ clipPath: `inset(0 ${computedUserProgress} 0 0)` }" class="progress-bar-container"
                  :src="computedSrcProgressBar" />
                <span class="progress-bar-text">{{ totalProsen }}%</span>
              </div>
            </div>
            <div class="profil-button">
              <img @click="toogleProfile" :src="require(`../assets/images/` + srcProfileButton)" />
              <div
                :class="`profil-items ${showProfileDropdown ? 'slide-down' : isTriggerButtonProfile ? 'slide-up' : 'hide'}`">
                <img class="profil-item" :src="require(`../assets/images/profile-information.webp`)" />
                <img class="profil-item" :src="require(`../assets/images/reset-password.webp`)" />
                <img class="profil-item" :style="{ cursor: 'pointer' }" @click="logout"
                  :src="require(`../assets/images/logout.webp`)" />
              </div>
            </div>
          </div>
          <Transition>
            <div v-if="showPerformance" class="island-frame">
              <div v-if="isChina">
                <img class="island-left-lampion floating-animation"
                  :src="require(`../assets/images/` + srcIslandLampion)" />
                <img class="island-right-lampion floating-animation"
                  :src="require(`../assets/images/` + srcIslandLampion)" />
              </div>
              <div v-if="isDubai">
                <img class="island-left-drone floating-animation"
                  :src="require(`../assets/images/` + srcIslandDrone)" />
                <img class="island-right-drone floating-animation"
                  :src="require(`../assets/images/` + srcIslandDrone)" />
                <img class="island-bottom-left-cloud" :src="require(`../assets/images/` + srcIslandCloud)" />
                <img class="island-bottom-right-cloud" :src="require(`../assets/images/` + srcIslandCloud)" />
              </div>
              <div v-if="isSwiss">
                <img class="island-swiss-bottom-left-cloud" :src="require(`../assets/images/` + srcIslandCloud)" />
                <img class="island-swiss-bottom-right-cloud" :src="require(`../assets/images/` + srcIslandCloud)" />
                <img class="island-swiss-bottom-right-second-cloud"
                  :src="require(`../assets/images/` + srcIslandCloud)" />
              </div>
              <div :class="classIsland">
                <div class="circle-progress" data-progress="50"></div>
                <!--img
                class="planet-here"
                src="../assets/images/themostrunner/here.png"
                :style="planetHereStyle"
              /-->
                <div class="container-planet">
                  <div v-for="(planet, index) in planetItems" :key="index" :class="planet.class" :style="planet.style"
                    :title="planet.idPlanet + ' : ' + planet.progresPersen + ' %'"
                    @click="planet.class.includes('inactive') ? null : showPlanetPopUp(planet.idPlanet)">
                    <img class="planet-progress-planet" :src="require(`../assets/images/` + planet.imgPlanet)" />
                    <div :style="{ visibility: tranparentTextPlanet ? 'hidden' : 'visible' }" class="planet-id">
                      {{ planet.class.includes('inactive') ? 'Terkunci' : getPreviousMonth(planet.idPlanet) }}
                    </div>
                    <div class="planet-value" ref="circleValue">
                      {{ planet.progresPersen }}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition>
            <div v-if="showEntertainment && !isComingSoon" class="full-content-container">
              <div class="content-container">
                <div class="entertainment-container">
                  <img @click="showComingSoon" class="entertainment-item"
                    :src="require(`../assets/images/` + srcEntertainmentGames)" />
                  <img @click="showComingSoon" class="entertainment-item"
                    :src="require(`../assets/images/` + srcEntertainmentMissions)" />
                  <img @click="showDailyCheckin" class="entertainment-item"
                    :src="require(`../assets/images/` + srcEntertainmentCheckin)" />
                </div>
              </div>
            </div>
          </Transition>
          <Transition>
            <div v-if="showFaq" class="full-content-container">
              <div class="content-container">
                <div class="faq-container">
                  <div class="faq-gridlabel-r1-c1">
                    <div @click="showTutorialOne" class="faq-video-container">
                      <img class="faq-item-video-border" src="../assets/images/border-faq.webp" />
                      <img class="faq-item-video-button" src="../assets/images/play-button.webp" />
                      <video class="faq-item-video" preload="auto" ref="videoElement" poster="../assets/images/video-talent-poster.png" width="320" height="240">
                        <source :src="require(`../assets/video/` + videoTutorialOne)" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                  <div class="faq-gridlabel-r2-c1">
                    <img @click="showTutorialOne" class="item-button"
                      :src="require(`../assets/images/` + srcButtonProgram)" />
                  </div>
                  <div class="faq-gridlabel-r1-c2">
                    <div @click="showTutorialTwo" class="faq-video-container">
                      <img class="faq-item-video-border" src="../assets/images/border-faq.webp" />
                      <img class="faq-item-video-button" src="../assets/images/play-button.webp" />
                      <video class="faq-item-video" preload="auto" ref="videoElement" @poster="`../assets/images/${this.videoTutorialTwoPoster}`" width="320" height="240">
                        <source :src="require(`../assets/video/` + videoTutorialTwo)" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                  <div class="faq-gridlabel-r2-c2">
                    <img @click="showTutorialTwo" class="item-button"
                      :src="require(`../assets/images/` + srcButtonTutorial)" />
                  </div>
                  <div class="faq-gridlabel-r1-c3">
                    <img class="faq-item-help" :src="require(`../assets/images/` + srcFAQHelpContain)" />
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition>
            <div v-if="showPromo" class="full-content-container">
              <div class="promo-content-container">
                <div class="promo-container">
                  <div class="promo-gridlabel-r1-c1-full">
                    <Swiper grabCursor="true" clickable="true" :effect="'coverflow'" :centeredSlides="true"
                      :slidesPerView="'auto'" :coverflowEffect="coverflowEffect" :breakpoints="breakpoints" :pagination="{
                        clickable: true
                      }" :modules="modules" @slideChange="handleSlideChange">

                      <SwiperSlide v-for="(slide, index) in promoImageCarouselSlides" :key="index"
                        :class="{ 'blurred': index !== activeImageCarouselIndex }">
                        <img class="promo-item" :src="require(`../assets/images/promo/${slide.src}`)" />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <div class="promo-gridlabel-r2-c1">
                    <a href="https://fifgroup.co.id/fifastra" target="_blank">
                      <img class="item-button-all-promo" :src="require(`../assets/images/` + srcButtonAllPromo)" />
                    </a>
                  </div>
                  <div class="promo-gridlabel-r2-c2">
                    <a href="https://fifgroup.co.id/fifastra" target="_blank">
                      <img class="item-button-download" :src="require(`../assets/images/` + srcButtonUnduhPromo)" />
                    </a>
                  </div>
                  <div class="promo-gridlabel-r2-c3">
                    <a href="https://fifgroup.co.id/fifastra" target="_blank">
                      <img class="item-button-news" :src="require(`../assets/images/` + srcButtonNews)" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <!-- <div :class="leftButtonMenu">
            <div class="arrow-button">
              <img
                @click="hideLeftMenu"
                src="../assets/images/speed/arrow.png"
              />
            </div>
            <div class="square-button" style="margin-bottom: 10%">
              <img
                @click="showPusatInformasi"
                src="../assets/images/button/pusat_info.png"
              />
            </div>
            <div class="square-button">
              <img
                @click="showPromoModal"
                src="../assets/images/button/promo.png"
              />
            </div>
            <div class="square-button">
              <img @click="showGames" src="../assets/images/button/game.png" />
            </div>
            <div class="square-button">
              <img
                @click="showCreditScore"
                src="../assets/images/button/monthly_score.png"
              />
            </div>
          </div> -->
          <!-- <div :class="leftButtonArrow">
            <div class="arrow-button" @click="showLeftMenu">
              <img src="../assets/images/speed/arrow.png" />
            </div>
          </div>
          <div class="play-button" @click="showChart">
            <img src="../assets/images/button/button.png" />
            <span class="text-play text-erg">
              P E R F O R M A N C E
              <br />
              {{ this.userProgres }}
            </span>
          </div>
          <div class="right-button">
            <div class="square-button" @click="showTutorial">
              <img src="../assets/images/themostrunner/tutor.png" />
            </div>
            <div class="square-button">
              <img src="../assets/images/themostrunner/games.png" />
            </div>
          </div> -->
        </div>
        <Transition>
          <div v-if="showQuestModal" class="full-screen modal-v" @click.self="hideQuest">
            <div class="close-modal" @click="hideQuest">
              <img src="../assets/images/close_icon.png" />
            </div>
            <div class="quest-frame">
              <div class="text-lg-mid text-xl">DAILY QUEST</div>
              <div v-for="(item, index) in dailyQuestItem" class="octagon-input-container" :key="index"
                @click.stop="handleItemClick(item.nama, index)">
                <div class="octagon-input-line bg-quest-line">
                  <div class="octagon-input text-left bg-quest-text">
                    <div class="text-left">{{ item.nama }}</div>
                    <div class="arrow-right">
                      <img src="../assets/images/arrow.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="showChartModal" class="full-screen modal-v" @click.self="hideChart">
            <div :class="classChartFrame">
              <div class="close-chart-modal" @click="hideChart">
                <img src="../assets/images/video/buttonx.webp" />
              </div>
              <div class="chart-container">
                <Line :data="chartLabel" :options="chartOptions" :plugins="chartPlugins" ref="chartPerformance" />
              </div>
              <!-- <div class="chart-gridlabel">
                <div class="chart-gridlabel-1">
                  Target :
                  {{ chartLabel.datasets[2]?.data[0] ?? 0 }}
                  <br />Actual :
                  {{ chartLabel.datasets[1]?.data[0] ?? 0 }}
                </div>
                <div class="chart-gridlabel-2">
                  Target : {{ chartLabel.datasets[2]?.data[1] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[1] ?? 0 }}
                </div>
                <div class="chart-gridlabel-3">
                  Target : {{ chartLabel.datasets[2]?.data[2] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[2] ?? 0 }}
                </div>
                <div class="chart-gridlabel-4">
                  Target : {{ chartLabel.datasets[2]?.data[3] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[3] ?? 0 }}
                </div>
                <div class="chart-gridlabel-5">
                  Target : {{ chartLabel.datasets[2]?.data[4] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[4] ?? 0 }}
                </div>
                <div class="chart-gridlabel-6">
                  Target : {{ chartLabel.datasets[2]?.data[5] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[5] ?? 0 }}
                </div>
                <div class="chart-gridlabel-7">
                  Target : {{ chartLabel.datasets[2]?.data[6] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[6] ?? 0 }}
                </div>
                <div class="chart-gridlabel-8">
                  Target : {{ chartLabel.datasets[2]?.data[7] ?? 0 }}
                  <br />Actual : {{ chartLabel.datasets[1]?.data[7] ?? 0 }}
                </div>
              </div> -->
              <div class="text-totallabel">
                Total = {{ this.formatNumber(totalActual) }} ({{
                  totalProsen
                }}
                %) <br />Actual = {{ this.formatNumber(totalTarget) }}
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="isDailyCheckin" class="full-screen modal-v modal-v-transparent">
            <div class="daily-close-modal" @click="hideDailyCheckin">
              <img src="../assets/images/video/buttonx.webp" />
            </div>
            <div :class="classDailyFrame">
              <div class="daily-header">
                <img :src="require(`../assets/svg/border-daily.svg`)" />
                <span>{{ monthYear }}</span>
              </div>
              <div class="calendar-table-container">
                <table class="calendar-table">
                  <tbody>
                    <tr v-for="(row, rowIndex) in calendarRows" :key="rowIndex">
                      <td v-for="(day, dayIndex) in row" :key="dayIndex" class="calendar-cell">
                        <div :style="{ visibility: visibleDate[day] ? 'visible' : 'hidden' }" class="calender-badge">
                          <span>{{ String(day).padStart(2, '0') }}</span>
                          <img v-if="checkedMap[day]" src="../assets/images/checkin-daily.webp" alt="Checkin Badge"
                            class="checkin-image" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="daily-footer">
                <span>DON'T MISS OUT! CHECK IN DAILY TO UNLOCK MORE REWARDS</span>
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="showTutorialModal" class="full-screen modal-v">
            <div :class="classFrame">
              <div class="vid-panduan">
                <img src="../assets/images/video/panduan.webp" />
              </div>
              <div class="vid-play">
                <!--img src="../assets/images/video/button playx.png" /-->
              </div>
              <div class="vid-skip" @click="hideTutorial">
                <img src="../assets/images/video/buttonx.webp" />
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="isPusatInformasi" @click.self="hidePusatInformasi" class="full-screen modal-v">
            <div class="close-modal" @click="hidePusatInformasi">
              <img src="../assets/images/close_icon.png" />
            </div>
            <div class="pusatinfo-frame">
              <div class="pusatinfo-container">
                <div class="text-pusatinfo">PUSAT INFORMASI</div>
                <div class="box-pusatinfo" @click="showTutorialOne">
                  <span>Video Program</span>
                </div>
                <div class="box-pusatinfo" @click="showTutorialTwo">
                  <span>Video Tutorial</span>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="isPromoModal" @click.self="hidePromoModal" class="full-screen modal-v">
            <div class="close-modal" @click="hidePromoModal">
              <img src="../assets/images/close_icon.png" />
            </div>
            <div class="pusatinfo-frame">
              <div class="pusatinfo-container">
                <div class="text-pusatinfo">PROMO</div>
                <a href="https://fifgroup.co.id/fifastra/promosi">
                  <div class="box-promo"><span>ALL PROMO</span></div>
                </a>
                <div class="box-promo">
                  <span>UNDUH PROMO</span>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="isCreditScore" @click.self="hideCreditScore" class="full-screen modal-v">
            <div class="close-modal" @click="hideCreditScore">
              <img src="../assets/images/close_icon.png" />
            </div>
            <div class="creditlog-frame">
              <div class="creditlog-header">{{ bulanCreditScore }}</div>
              <div class="creditlog-container">
                <table class="creditlog-table">
                  <thead>
                    <th>SEN</th>
                    <th>SEL</th>
                    <th>RAB</th>
                    <th>KAM</th>
                    <th>JUM</th>
                    <th>SAB</th>
                    <th>MIN</th>
                  </thead>
                  <tr v-for="(tanggalan, index) in creditMonthly" :key="index">
                    <td v-for="(hari, index) in tanggalan" :key="index">
                      <img v-if="hari !== null" class="creditlog-img"
                        :src="require(`../assets/images/creditlog/` + hari)" />
                    </td>
                  </tr>
                </table>
              </div>
              <span class="creditlog-text">{{ creditMonthlyAktif }} / {{ totalDayMonth }}</span>
              <div class="creditlog-redeem">
                <img src="../assets/images/creditlog/redeem.png" />
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="isComingSoon" class="full-screen modal-v comingsoon-page">
            <div class="comingsoon-content">
              <div class="comingsoon-frame">
                <div class="text-comingsoon">
                  COMING SOON
                </div>
                <div class="back-page-button" @click="hideComingSoon">
                  <img :src="require(`../assets/images/` + srcButtonBack)" />
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="isPlanetPopUp" @click.self="hidePlanetPopUp" class="full-screen modal-v">
            <div :class="classPopupFrame">
              <div class="popup-close-modal" @click="hidePlanetPopUp">
                <img src="../assets/images/video/buttonx.webp" />
              </div>
              <div class="planetpopup-kiri">
                <div :class="planetPopupClass" :style="planetPopUpStyle">
                  <img class="planetpopup-planet" :src="require(`../assets/images/` + planetPopUpSrc)" />
                  <div class="planetpopup-value">{{ planetPopUpPersen }} %</div>
                </div>
              </div>
              <div class="planetpopup-kanan">
                <div>
                  <div class="planetpopup-header">{{ planetPopUpHeader }}</div>
                  <table class="planetpopup-table">
                    <tr>
                      <td>Target</td>
                      <td>:</td>
                      <td>{{ planetPopUpTarget }}</td>
                    </tr>
                    <tr>
                      <td>Actual</td>
                      <td>:</td>
                      <td>{{ planetPopUpCapaian }}</td>
                    </tr>
                    <tr>
                      <td>Achievement</td>
                      <td>:</td>
                      <td>{{ planetPopUpPersen }} %</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="isSpeedGames" class="full-screen china-style modal-speed">
            <div class="close-modal speed" @click="hideGames">
              <img src="../assets/images/close_icon.png" />
            </div>
            <SpeedGames />
          </div>
        </Transition>
        <Transition>
          <div v-if="isHunterGames" class="full-screen huntergames-style modal-hunter">
            <div class="close-modal hunter" @click="hideGames">
              <img src="../assets/images/close_icon.png" />
            </div>
            <HunterGames />
          </div>
        </Transition>
        <Transition>
          <div v-if="isRunnerGames" class="full-screen runnergames-style modal-runner">
            <div class="close-modal runner" @click="hideGames">
              <img src="../assets/images/close_icon.png" />
            </div>
            <RunnerGames />
          </div>
        </Transition>
      </div>
    </Transition>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { EffectCoverflow, Pagination } from 'swiper/modules';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
import SpeedGames from "./SpeedGamesEasy.vue";
import HunterGames from "./HunterGamesEasy.vue";
import RunnerGames from "./RunnerGamesTembok.vue";

const pointImage = new Image();
//pointImage.src = "http://admin.fl-fifastra.com/assets/images/pointstyle.png";
pointImage.src = require("../assets/images/chart/pointstyle.png");
pointImage.width = window.innerWidth / 80;
pointImage.height = pointImage.width;
export default {
  name: "MainScreen",
  components: {
    Line,
    SpeedGames,
    HunterGames,
    RunnerGames,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination],
    };
  },
  data() {
    return {
      calendarRows: [],
      checkedMap: {},
      monthYear: "Januari 2025",
      visibleDate: {},
      username: this.$store.getters.getUserData.username,
      message: "",
      classSplashLogo: "splash-logo-main",
      classIsland: "island island-china",
      textNama: this.$store.getters.getUserData.nama,
      textWriterAtas: "",
      textWriterBawah: "",
      textAtasPos: 0,
      textBawahPos: 0,
      userGames: this.$store.getters.getUserData.games,
      userRank: "Challenger",
      userProgres: "26%",
      writeSpeed: 200,
      i: 0,
      showTypeWriter: false,
      planetPopUpHeader: "januari 2024",
      planetPopupClass: "planetpopup-container",
      classFrame: "video-frame china-frame",
      classPopupFrame: "planetpopup-frame china-popup-frame",
      classDailyFrame: "daily-frame daily-china-frame",
      classWelcomeText: "china-welcome-text",
      planetPopUpStyle: "",
      planetPopUpSrc: "china/point-low.webp",
      planetPopUpBadge: "chalenger.png",
      planetPopUpTarget: 0,
      planetPopUpCapaian: 0,
      planetPopUpPersen: 0,
      srcSplashLogo: "china/logo.webp",
      srcSplashWelcome: "welcome.webp",
      srcPlanetActive: "china/point.webp",
      srcPlanetInactive: "china/point-inactive.webp",
      srcProfileButton: "china/profile-button.webp",
      srcEntertainmentGames: "china/entertainment-games.webp",
      srcEntertainmentMissions: "china/entertainment-missions.webp",
      srcEntertainmentCheckin: "china/entertainment-checkin.webp",
      srcButtonTutorial: "china/faq-tutorial.webp",
      srcButtonProgram: "china/faq-program.webp",
      srcButtonAllPromo: "china/promo-all-promo.webp",
      srcButtonUnduhPromo: "china/promo-unduh-promo.webp",
      srcButtonNews: "china/promo-news.webp",
      srcButtonBack: "china/button-back.webp",
      srcFAQHelpContain: "china/faq-scroll-bantuan.webp",
      srcIslandLampion: "lampion-china.webp",
      srcIslandDrone: "drone-dubai.webp",
      srcIslandCloud: "fog-swiss-waterfall.webp",
      classEntertainment: "nav-entertainment china-entertainment",
      classPerformance: "nav-performance china-performance",
      classPromo: "nav-promo china-promo",
      classFaq: "nav-faq china-faq",
      classChartFrame: "chart-frame china-chart-frame",
      srcBacksound: "speed-music.mp3",
      audioVolume: 0.5,
      showWelcomeScreen: true,
      showWelcomeText: true,
      showQuestModal: false,
      showChartModal: false,
      showTutorialModal: false,
      showProfileDropdown: false,
      showEntertainment: false,
      showPerformance: true,
      showPromo: false,
      showFaq: false,
      tranparentTextPlanet: true,
      isChina: false,
      isDubai: false,
      isSwiss: false,
      activeImageCarouselIndex: 0,
      promoImageCarouselSlides: [
        { src: 'promo-one.webp' },
        { src: 'promo-two.webp' },
        { src: 'promo-three.webp' }
      ],
      isTriggerButtonProfile: false,
      isDailyCheckin: false,
      isPromosiAwal: false,
      isGimmick: false,
      isTutorialOne: true,
      isNextTutorialTwo: true,
      isTutorialTwo: false,
      videoGimmick: "",
      videoTutorialOne: "talentspeed.mp4",
      videoTutorialTwo: "tutorialspeed.mp4",
      videoTutorialTwoPoster: "video-tutorial-poster-speed.png",
      isPusatInformasi: false,
      isPromoModal: false,
      isCreditScore: false,
      isComingSoon: false,
      isPlanetPopUp: false,
      isSpeedGames: false,
      isHunterGames: false,
      isRunnerGames: false,
      showAudio: false,
      isPortrait: window.innerWidth < 576,
      leftButtonMenu:
        window.innerWidth > 767
          ? "left-button menu-in"
          : "left-button menu-out",
      leftButtonArrow:
        window.innerWidth > 767
          ? "hide-button menu-out"
          : "hide-button menu-in",
      bulanCreditScore: "",
      creditMonthly: [
        "Day 1",
        "Day 2",
        "Day 3",
        "Day 4",
        "Day 5",
        "Day 6",
        "Day 7",
        "Day 8",
        "Day 9",
        "Day 10",
        "Day 11",
        "Day 12",
        "Day 13",
        "Day 14",
        "Day 15",
        "Day 16",
        "Day 17",
        "Day 18",
        "Day 19",
        "Day 10",
        "Day 21",
        "Day 22",
        "Day 23",
        "Day 24",
        "Day 25",
        "Day 26",
        "Day 27",
        "Day 28",
        "Day 29",
        "Day 30",
        "Day 31",
      ],
      creditMonthlyAktif: 0,
      totalDayMonth: 0,
      splashItems: [
        {
          class: "splash-cloud-left",
          cloudImg: [
            {
              class: "cloud-left-1",
              src: `awan.png`,
              alt: "cloud",
            },
          ],
        },
        {
          class: "splash-cloud-right",
          cloudImg: [
            {
              class: "cloud-right-1",
              src: `awan.png`,
              alt: "cloud",
            },
          ],
        },
      ],
      planetHereStyle: `top: 41%; left: 82.5%;`, // TOP-4 || LEFT + 3.5
      planetItems: [
        {
          class: "planet-progress",
          idPlanet: "JANUARI",
          style: `top: 40%; left: 75%;`,
          progresPersen: 30,
          imgPlanet: "china/point-low.webp",
          srcRank: "chalenger.png",
        },
        {
          class: "planet-progress",
          idPlanet: "FEBRUARI",
          style: `top: 26%; left: 91%;`,
          progresPersen: 60,
          imgPlanet: "china/point-mid.webp",
          srcRank: "chalenger.png",
        },
        {
          class: "planet-progress",
          idPlanet: "MARET",
          style: `top: 13%; left: 72%;`,
          progresPersen: 100,
          imgPlanet: "china/point-high.webp",
          srcRank: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "APRIL",
          style: `top: 33%; left: 52%;`,
          progresPersen: 0,
          imgPlanet: "china/point-inactive.webp",
          srcRank: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "MEI",
          style: `top: 37%; left: 30%;`,
          progresPersen: 0,
          imgPlanet: "china/point-inactive.webp",
          srcRank: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "JUNI",
          style: `top: 25%; left: 13%;`,
          progresPersen: 0,
          imgPlanet: "china/point-inactive.webp",
          srcRank: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "JULI",
          style: `top: 8%; left: 22%;`,
          progresPersen: 0,
          imgPlanet: "china/point-inactive.webp",
          srcRank: "chalenger.png",
        },
        {
          class: "planet-progress inactive",
          idPlanet: "AGUSTUS",
          style: `top: 3%; left: 44%;`,
          progresPersen: 0,
          imgPlanet: "china/point-inactive.webp",
          srcRank: "chalenger.png",
        },
      ],
      dailyQuestItem: [
        { nama: "PLAY THE GAME TODAY" },
        { nama: "WATCH THE FOLLOWING VIDEO" },
        { nama: "LIKE AND POST" },
      ],
      totalActual: 0,
      totalTarget: 0,
      totalProsen: 0,
      chartLabel: {
        labels: [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          /*"SEP",
          "OKT",
          "NOV",
          "DES",*/
        ],
        datasets: [
          {
            label: false,
            borderColor: "#FFBD2B", // warna garis
            pointBackgroundColor: "#fff0",
            pointBorderColor: "#fff",
            pointStyle: pointImage,
            pointRadius: 10,
            pointHoverRadius: 15,
            borderWidth: 2,
            /*radius: 0,*/
            fill: false,
            /*
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx;
              const gradient = canvas.createLinearGradient(0, 0, 0, 160);

              gradient.addColorStop(0, "#c9ccdb");
              gradient.addColorStop(0.5, "#8b97e6");
              gradient.addColorStop(1, "#0b29e4");

              return gradient;
            },
            barThickness: 50,*/
            data: [],
          },
          {
            label: false,
            hidden: true,
            data: [],
          },
          {
            label: false,
            hidden: true,
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
            text:
              "PERFORMANCE \n" + this.$store.getters.getUserData.nama,
            font: {
              size: 25,
              weight: "bold",
              color: "white",
              padding: {
                top: 10,
                bottom: 20,
              },
            },
          },
          datalabels: {
            anchor: "end",
            align: "end",
            clamp: true,
            color: "#7c9196",
            backgroundColor: "white",
            borderRadius: 50,
            padding: 5,
            formatter: function (value) {
              return value + " %";
            },
            display: false,
          },
          tooltip: {
            enabled: false,
            displayColors: false,
            /*callbacks: {
              title: function (data) {
                const value = data[0].raw;
                //return "";
                if (value >= 100) {
                  return "MELEBIHI TARGET";
                } else {
                  return "";
                }
              },
              label: function (context) {
                //const bulan = context.label;
                //const value = context.parsed.y;
                const datasets = context.chart.data.datasets;
                const nilaiAsli = datasets[1].data[context.dataIndex];
                const targetAsli = datasets[2].data[context.dataIndex];
                //const persenAsli = datasets[2].data[context.dataIndex];
                //return `${bulan} : ${nilaiAsli} Penjualan (${persenAsli} %)`;
                return `Target : ${targetAsli} || Achievement : ${nilaiAsli}`;
              },
              labelPointStyle: function (context) {
                console.log(context);
                const pointImage = new Image();
                pointImage.src;
                pointImage.src = "https://img.icons8.com/bubbles/30/star.png";
                  return { pointStyle: pointImage };
                /*const value = context.parsed.y;
                if (value >= 80) {
                  return { pointStyle: "rectRot" };
                } else {
                  return { pointStyle: "rectRot" };
                }

              },
            },*/
          },
        },
        layout: {
          padding: {
            bottom: 0, // Initial bottom padding
            right: 0,
          }
        },
        scales: {
          x: {
            ticks: {
              // tulisan y
              color: "white",
              backgroundColor: "green",
              font: {
                size: 0,
                weight: "bold",
              },
              padding: 0,
            },
            grid: {
              display: false, // Hides the vertical grid lines
            },
          },
          y: {
            min: 0,
            max: 180,
            borderColor: "blue",
            ticks: {
              // tulisan y
              color: "#FFBD2B",
              font: {
                size: 0,
                weight: "bold",
                color: "#FFBD2B",
              },
              padding: 0,
            },
            grid: {
              display: false, // Hides the vertical grid lines
            },
          },
        },
        onResize: (chart, size) => {
          const newFontSize = Math.max(10, size.width / 65);
          const newPaddingBottom = Math.max(size.height * 0.2, 20);
          const newPaddingRight = Math.max(size.height * 0.14, 20);
          const newPointRadius = size.height * 0.018; // Minimum size 5
          const newHoverRadius = size.height * 0.019; // Minimum hover size 7

          // Apply changes to all datasets
          chart.data.datasets.forEach(dataset => {
            dataset.pointRadius = newPointRadius;
            dataset.pointHoverRadius = newHoverRadius;
          });
          chart.options.scales.x.ticks.font.size = newFontSize;
          chart.options.scales.y.ticks.font.size = newFontSize;
          chart.options.layout.padding.bottom = newPaddingBottom;
          chart.options.layout.padding.right = newPaddingRight;

          chart.update(); // Re-render the chart with updated options
        }, /*
        layout: {
          padding: {
            top: 50,
          },
        },*/
      },
      chartPlugins: [
        {
          afterDraw: (chart) => {
            //console.log(chart);
            const ctx = chart.ctx;
            const xAxis = chart.scales["x"];
            const yAxis = chart.scales["y"];

            //imageBawah.src = "https://img.icons8.com/bubbles/30/star.png";
            xAxis.ticks.forEach((value, index) => {
              let x = xAxis.getPixelForTick(index);
              // nanti mengambil image berdasar index
              var imageBawah = new Image();
              // imageBawah.src = require(`../assets/images/` + this.switchPopUpSrc(this.planetItems[index].progresPersen, false));
              imageBawah.src = require(`../assets/images/` + this.planetItems[index].imgPlanet.replace('point-inactive', 'point-low'));
              ctx.drawImage(
                imageBawah,
                x - chart.width / 40,
                yAxis.bottom + (chart.width / 85),
                chart.width / 20,
                chart.width / 20
              );

              // Add label below the image
              const labelPercent = `${this.planetItems[index].progresPersen ? this.planetItems[index].progresPersen : 0}%`; // Replace with your dynamic label
              ctx.font = "0.7vw Anton Rg"; // Adjust font size and style as needed
              ctx.fillStyle = "white"; // Set text color
              ctx.textAlign = "center"; // Align text to the center
              ctx.fillText(labelPercent, x, yAxis.bottom + (chart.width * 0.04)); // Adjust position as needed

              const labelName = `${this.getPreviousMonth(this.planetItems[index].idPlanet).substring(0, 3)}`; // Replace with your dynamic label
              ctx.font = "0.8vw Anton Rg"; // Adjust font size and style as needed
              ctx.fillStyle = "#FFBD2B"; // Set text color
              ctx.fillText(labelName, x, yAxis.bottom + chart.height / 6.5);
              const labelTarget = `TARGET : ${this.chartLabel.datasets[2]?.data[index] ?? 0}`; // Replace with your dynamic label
              const labelActual = `ACTUAL : ${this.chartLabel.datasets[1]?.data[index] ?? 0}`; // Replace with your dynamic label
              ctx.font = "1vw Knight Warrior"; // Adjust font size and style as needed
              ctx.fillStyle = "white"; // Set text color
              ctx.textAlign = "left";
              ctx.fillText(labelTarget, x - chart.height / 18, yAxis.bottom + chart.height / 5);
              ctx.fillText(labelActual, x - chart.height / 18, yAxis.bottom + chart.height / 4.2);
            });
          },
        },
      ],
      coverflowEffect: {
        rotate: 0,
        stretch: window.innerWidth / 1.535,
        depth: 250,
        modifier: 1,
        slideShadows: false,
      },
    };
  },
  methods: {
    handleSlideChange(swiper) {
      this.activeImageCarouselIndex = swiper.activeIndex;
    },
    async getPlanet() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getplanet",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.username,
              token: this.$store.getters.getUserData.token,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            this.planetItems = data.data;
          }
          // console.log(data.message);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    },
    async getDataAwal() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getdataawal",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.username,
              token: this.$store.getters.getUserData.token,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            //this.planetHereStyle = data.planetHereStyle;
            //this.textNama = data.textNama;
            this.userProgres = data.data.userProgres;
            //this.planetItems = data.data;
          }
          // console.log(data.message);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
    toogleProfile() {
      this.showProfileDropdown = !this.showProfileDropdown;
      this.isTriggerButtonProfile = true;
    },
    enterMainView() {
      this.classSplashLogo = "splash-logo-main scale-up-center-r";
      this.splashItems[0].class = "splash-cloud-left slide-left-r";
      this.splashItems[1].class = "splash-cloud-right slide-right-r";
      setTimeout(() => {
        this.splashItems[0].class = "splash-cloud-left slide-left";
        this.splashItems[1].class = "splash-cloud-right slide-right";
        this.showWelcomeScreen = false;
      }, 500);
    },
    enterDashboard() {
      this.showWelcomeText = false;
      this.showTypeWriter = true;
      this.typeWriterAtas();
      setTimeout(() => {
        this.typeWriterBawah();
      }, 2800);
      /*
      const interval = setInterval(() => {
        if (this.$refs.typeWriter) {
          this.typeWriter();
          clearInterval(interval);
        }
      }, 50);
      */
    },
    async showPlanetPopUp(idPlanet) {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getrincianplanet",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.username,
              token: this.$store.getters.getUserData.token,
              idplanet: idPlanet,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            // console.log("Berhasil mengambil data");
            this.planetPopUpHeader = this.getPreviousMonth(data.data.bulan.replace(' 2025', '')) + ` ${data.data.bulan == 'JANUARI 2025' ? '2024' : '2025'}`;
            this.planetPopupClass = data.data.planetclass;
            this.planetPopUpStyle = "";
            this.planetPopUpSrc = data.data.imgsrc;
            // this.planetPopUpSrc = this.switchPopUpSrc(data.data.persen);
            this.planetPopUpBadge = data.data.badgesrc;
            this.planetPopUpTarget = data.data.target;
            this.planetPopUpCapaian = data.data.capaian;
            this.planetPopUpPersen = data.data.persen;
            this.isPlanetPopUp = true;
          } else {
            console.log(data.message);
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    switchPopUpSrc(value, allowInactive = true) {
      const areaMap = {
        SPEED: 'china',
        HUNTER: 'dubai',
        RUNNER: 'swiss',
      };
      const area = areaMap[this.userGames] || 'china';
      const pointLevel = allowInactive && value === null ? 'inactive' : value >= 100 ? 'high' : value >= 60 ? 'mid' : 'low';
      return `${area}/point-${pointLevel}.webp`;
    },
    hidePlanetPopUp() {
      this.isPlanetPopUp = false;
    },
    hideLeftMenu() {
      this.leftButtonMenu = "left-button menu-out";
      this.leftButtonArrow = "hide-button menu-in";
    },
    showLeftMenu() {
      this.leftButtonMenu = "left-button menu-in";
      this.leftButtonArrow = "hide-button menu-out";
    },
    showQuest() {
      this.showQuestModal = true;
    },
    hideQuest() {
      this.showQuestModal = false;
    },
    showPusatInformasi() {
      this.isPusatInformasi = true;
    },
    hidePusatInformasi() {
      this.isPusatInformasi = false;
    },
    showPromoModal() {
      this.isPromoModal = true;
    },
    hidePromoModal() {
      this.isPromoModal = false;
    },
    async showDailyCheckin() {
      this.isDailyCheckin = true;
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getdailycheckin",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.username,
              token: this.$store.getters.getUserData.token,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            // console.log("Berhasil mengambil data");
            // FUNGSI BUAT KALENDER
            const monthNames = {
              0: "Januari",
              1: "Februari",
              2: "Maret",
              3: "April",
              4: "Mei",
              5: "Juni",
              6: "Juli",
              7: "Agustus",
              8: "September",
              9: "Oktober",
              10: "November",
              11: "Desember",
            };
            const templateDate = {
              1: false,
              2: false,
              3: false,
              4: false,
              5: false,
              6: false,
              7: false,
              8: false,
              9: false,
              10: false,
              11: false,
              12: false,
              13: false,
              14: false,
              15: false,
              16: false,
              17: false,
              18: false,
              19: false,
              20: false,
              21: false,
              22: false,
              23: false,
              24: false,
              25: false,
              26: false,
              27: false,
              28: false,
              29: false,
              30: false,
              31: false,
            };

            const checkedData = data.data;

            const totalDaysInMonth = 31; // Example for 31 days in the month, can be dynamic based on the current month
            const rows = Math.ceil(totalDaysInMonth / 10); // 10 columns per row

            // Create the rows array with day numbers
            const calculateCalendarRows = Array.from({ length: rows }, (_, rowIndex) => {
              const startDay = rowIndex * 10 + 1;
              const endDay = Math.min(startDay + 9, totalDaysInMonth);
              return Array.from({ length: endDay - startDay + 1 }, (_, dayIndex) => startDay + dayIndex);
            });

            const convertedCheckedData = Object.fromEntries(
              Object.entries(checkedData).map(([date, value]) => {
                const day = parseInt(date.split("-")[2], 10); // Extract the day as an integer
                return [day, value];
              })
            );

            // Get the first date key from the object
            const firstDateKey = Object.keys(checkedData)[0];
            // Extract the month and year from the date string (YYYY-MM-DD format)
            const [year, month] = firstDateKey.split('-');

            const lastDate = Object.keys(checkedData).pop();
            // Extract the day from the last date (YYYY-MM-DD)
            const lastDay = parseInt(lastDate.split('-')[2]);
            // Loop through templateDate and mark true up to the last day
            for (let i = 1; i <= lastDay; i++) {
              templateDate[i] = true;
            }
            this.visibleDate = templateDate;
            this.monthYear = `${monthNames[parseInt(month, 10) - 1]} ${year}`;
            this.calendarRows = calculateCalendarRows;
            this.checkedMap = convertedCheckedData;
            this.isDailyCheckin = true;
          } else {
            console.log(data.message);
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    hideDailyCheckin() {
      this.isDailyCheckin = false;
    },
    async showCreditScore() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getmonthly",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.username,
              token: this.$store.getters.getUserData.token,
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.code == "200") {
            // console.log("Berhasil mengambil data");
            // FUNGSI BUAT KALENDER
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();

            const daysInMonth = new Date(
              currentYear,
              currentMonth + 1,
              0
            ).getDate();
            const firstDayOfMonth = new Date(
              currentYear,
              currentMonth,
              1
            ).getDay();

            let dayCount = 1;
            let aktif = 0;
            const calendar = [];

            for (let i = 0; i < 6; i++) {
              const week = [];
              for (let j = 0; j < 7; j++) {
                if (i === 0 && j < firstDayOfMonth - 1) {
                  // Empty cells before the first day of the month
                  week.push(null);
                  //week.push("badges.png");
                } else if (dayCount <= daysInMonth) {
                  //week.push(dayCount);
                  week.push(data.data[dayCount].imgsrc);
                  if (data.data[dayCount].imgsrc == "bintang_active.png") {
                    aktif++;
                  }
                  dayCount++;
                } else {
                  // Empty cells after the last day of the month
                  week.push(null);
                  //week.push("badges.png");
                }
              }
              //calendar.push(week);
              if (week.some((day) => day !== null)) {
                calendar.push(week);
              }
            }
            this.creditMonthly = calendar;
            this.creditMonthlyAktif = aktif;
            this.totalDayMonth = dayCount;
            this.isCreditScore = true;
            const refBulan = {
              0: "Januari",
              1: "Februari",
              2: "Maret",
              3: "April",
              4: "Mei",
              5: "Juni",
              6: "Juli",
              7: "Agustus",
              8: "September",
              9: "Oktober",
              10: "November",
              11: "Desember",
            };
            this.bulanCreditScore = refBulan[currentMonth] + " " + currentYear;
          } else {
            console.log(data.message);
          }
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    hideCreditScore() {
      this.isCreditScore = false;
    },
    cekBadge(badge) {
      const listBadge = {
        Silver: "chalenger.png",
        Gold: "grandmaster.png",
        Bronze: "master.png",
      };
      return listBadge[badge];
    },
    async getDataChart() {
      try {
        const response = await fetch(
          "https://admin.fl-fifastra.com/api/getcapaian",
          {
            method: "POST",
            body: JSON.stringify({
              username: this.username,
              token: this.$store.getters.getUserData.token,
            }),
          }
        );
        if (response.ok) {
          this.totalActual = 0;
          this.totalTarget = 0;
          const data = await response.json();
          //if (data.code == "200") {
          // console.log("Berhasil mengambil data");
          this.chartLabel.datasets[0].data = data.data?.persen ?? 0;
          this.chartLabel.datasets[1].data = data.data?.capaian ?? 0;
          this.chartLabel.datasets[2].data = data.data?.target ?? 0;
          this.chartLabel.datasets[1].data.forEach((data) => {
            try {
              this.totalActual += parseInt(data);
            } catch (err) {
              console.error(err);
            }
          });
          this.chartLabel.datasets[2].data.forEach((data) => {
            try {
              this.totalTarget += parseInt(data);
            } catch (err) {
              console.error(err);
            }
          });
          this.totalProsen = (
            (this.totalActual / this.totalTarget) *
            100
          ).toFixed(1);
        } else {
          console.error("Gagal terhubung ke API Server");
        }
      } catch (error) {
        console.error("Gagal mengambil data :", error);
      }
    },
    async showChart() {
      await this.getDataChart();
      this.showChartModal = true;
    },
    hideChart() {
      this.showChartModal = false;
    },
    showTutorial() {
      this.showTutorialModal = true;
    },
    hideTutorial() {
      this.showTutorialModal = false;
    },
    showGimmick() {
      (this.isGimmick = true), (this.isPusatInformasi = false);
      this.isTutorialOne = false;
      this.isTutorialTwo = false;
      this.showAudio = false;
    },
    showTutorialOne() {
      this.isPusatInformasi = false;
      this.isTutorialOne = true;
      this.isTutorialTwo = false;
      this.showAudio = false;
    },
    showTutorialTwo() {
      this.isPusatInformasi = false;
      this.isTutorialOne = false;
      this.isTutorialTwo = true;
      this.showAudio = true;
    },
    hidePromosiAwal() {
      this.isPromosiAwal = false;
      this.isTutorialOne = true;
      this.isTutorialTwo = false;
    },
    hideGimmick() {
      this.isGimmick = false;
      this.isTutorialOne = true;
      this.isTutorialTwo = false;
      //this.showAudio = true;
    },
    hideTutorialOne() {
      this.isPusatInformasi = false;
      this.isTutorialOne = false;
      this.isTutorialTwo = this.isNextTutorialTwo;
      this.showAudio = !this.isNextTutorialTwo;
      this.isNextTutorialTwo = false;
    },
    hideTutorialTwo() {
      this.isPusatInformasi = false;
      this.isTutorialOne = false;
      this.isTutorialTwo = false;
      this.showAudio = !this.isNextTutorialTwo;
    },
    showComingSoon() {
      this.isComingSoon = true;
    },
    hideComingSoon() {
      this.isComingSoon = false;
    },
    showGames() {
      if (this.userGames == "SPEED") {
        this.isSpeedGames = true;
        this.showAudio = false;
      } else if (this.userGames == "HUNTER") {
        this.isHunterGames = true;
        this.showAudio = false;
      } else if (this.userGames == "RUNNER") {
        this.isRunnerGames = true;
        this.showAudio = false;
      }
    },
    hideGames() {
      if (this.userGames == "SPEED") {
        this.isSpeedGames = false;
        this.showAudio = true;
      } else if (this.userGames == "HUNTER") {
        this.isHunterGames = false;
        this.showAudio = true;
      } else if (this.userGames == "RUNNER") {
        this.isRunnerGames = false;
        this.showAudio = true;
      }
    },
    handleItemClick(item, index) {
      // Your click handling logic here
      console.log(`Clicked on ${item} at index ${index}`);
    },
    typeWriterAtas() {
      const yy = "Welcome";
      if (this.textAtasPos < yy.length) {
        var ketik = yy.charAt(this.textAtasPos);
        try {
          this.textWriterAtas += ketik;
        } catch {
          console.log("error animation typeWiter");
        }
        this.textAtasPos++;
        setTimeout(this.typeWriterAtas, this.writeSpeed);
      }
    },
    typeWriterBawah() {
      let nama = this.textNama
      if (this.textNama) {
        nama = this.textNama.toLowerCase()
      }
      let zz = nama;
      if (this.textBawahPos < zz.length) {
        var ketik = zz.charAt(this.textBawahPos);
        try {
          this.textWriterBawah += ketik;
        } catch {
          console.log("error animation typeWiter");
        }
        this.textBawahPos++;
        setTimeout(this.typeWriterBawah, this.writeSpeed);
      }
    },
    resizeWindow() {
      this.isPortrait = window.innerWidth < 576;
      if (window.innerWidth >= 576) {
        this.tranparentTextPlanet = true;
        setTimeout(() => {
          this.tranparentTextPlanet = false;
        }, 500);
      }
      //this.isPortrait = false;
    },
    formatNumber(x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
    },
    setPromosiAwal() {
      this.isPromosiAwal = true;
      this.isTutorialOne = false;
      this.hidePromosiAwal();   // hide promosi awal
      this.hideTutorialOne(); // hide tutorial one
      this.showAudio = true; // play music in tutorial two
    },
    setGimmickVideo() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();
      const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
      //console.log(currentDate);
      //console.log(currentYear);
      //console.log(currentMonth);
      //console.log(currentDay);
      // VIDEO TIAP TANGGAL 1
      if (currentDay == 1) {
        this.videoGimmick = this.getGimmickOpening(currentMonth);
        /*
        if (this.videoGimmick == "") {
          this.isGimmick = false;
          this.isTutorialOne = true;
        } else {
          this.isGimmick = true;
          this.isTutorialOne = false;
        }
        */
      }
      // VIDEO TENGAH BULAN
      else if (currentDay == 15) {
        this.videoGimmick = this.getGimmickHalf(currentMonth);
        //this.isGimmick = true;
        //this.isTutorialOne = false;
      }
      // VIDEO AKHIR BULAN
      else if (currentDay == daysInMonth) {
        this.videoGimmick = this.getGimmickClosing();
        //this.isGimmick = true;
        //this.isTutorialOne = false;
      }
    },
    getGimmickOpening(bulan) {
      const listVideo = {
        SPEED: {
          4: "speed-april.mp4",
          5: "speed-mei.mp4",
          6: "speed-juni.mp4",
          7: "speed-juli.mp4",
          8: "speed-agustus.mp4",
        },
        HUNTER: {
          4: "hunter-april.mp4",
          5: "hunter-mei.mp4",
          6: "hunter-juni.mp4",
          7: "hunter-juli.mp4",
          8: "hunter-agustus.mp4",
        },
        RUNNER: {
          4: "runner-april.mp4",
          5: "runner-mei.mp4",
          6: "runner-juni.mp4",
          7: "runner-juli.mp4",
          8: "runner-agustus.mp4",
        },
      };
      if (
        Object.prototype.hasOwnProperty.call(listVideo[this.userGames], bulan)
      ) {
        return listVideo[this.userGames][bulan];
      } else {
        /*
        if (this.userGames == "SPEED") {
          return "defaultspeed.mp4";
        } else if (this.userGames == "HUNTER") {
          return "";
        } else if (this.userGames == "RUNNER") {
          return "";
        }
        */
        return "";
      }
    },
    getPreviousMonth(currentMonth) {
      const months = [
        'JANUARI', 'FEBRUARI', 'MARET', 'APRIL', 'MEI', 'JUNI',
        'JULI', 'AGUSTUS', 'SEPTEMBER', 'OKTOBER', 'NOVEMBER', 'DESEMBER'
      ];

      const currentIndex = months.indexOf(currentMonth);
      if (currentIndex === 0) {
        return months[11]; // Jika Januari, kembalikan Desember
      } else {
        return months[currentIndex - 1];
      }
    },
    getGimmickHalf(bulan) {
      const listVideo = {
        SPEED: "speed-half.mp4",
        HUNTER: "hunter-half.mp4",
        RUNNER: "runner-half.mp4",
      };
      const listVideoMaret = {
        SPEED: "speed-half-maret.mp4",
        HUNTER: "hunter-half-maret.mp4",
        RUNNER: "runner-half-maret.mp4",
      };
      if (bulan == 3) {
        return listVideoMaret[this.userGames];
      } else {
        return listVideo[this.userGames];
      }
    },
    getGimmickClosing() {
      const listVideo = {
        SPEED: "speed-closing.mp4",
        HUNTER: "hunter-closing.mp4",
        RUNNER: "runner-closing.mp4",
      };
      return listVideo[this.userGames];
    },
    handleNavBar(value) {
      this.activeImageCarouselIndex = 0;
      if (value == "ENTERTAINMENT") {
        this.showEntertainment = true;
        this.showPerformance = false;
        this.showPromo = false;
        this.showFaq = false;
      } else if (value == "PERFORMANCE") {
        this.tranparentTextPlanet = true;
        this.showEntertainment = false;
        this.showPerformance = true;
        this.showPromo = false;
        this.showFaq = false;
        setTimeout(() => {
          this.tranparentTextPlanet = false;
        }, 450);
      } else if (value == "PROMO") {
        this.showEntertainment = false;
        this.showPerformance = false;
        this.showPromo = true;
        this.showFaq = false;
      } else if (value == "FAQ") {
        this.showEntertainment = false;
        this.showPerformance = false;
        this.showPromo = false;
        this.showFaq = true;
      }

    }
  },
  computed: {
    computedSrcProgressBarBorder() {
      if (this.userGames == "SPEED") {
        return require(`../assets/images/china/progress-bar-border.webp`);
      } else if (this.userGames == "HUNTER") {
        return require(`../assets/images/dubai/progress-bar-border.webp`);
      } else if (this.userGames == "RUNNER") {
        return require(`../assets/images/swiss/progress-bar-border.webp`);
      } else {
        return require(`../assets/images/china/progress-bar-border.webp`);
      }
    },
    computedSrcProgressBar() {
      if (this.userGames == "SPEED") {
        return require(`../assets/images/china/progress-bar.webp`)
      } else if (this.userGames == "HUNTER") {
        return require(`../assets/images/dubai/progress-bar.webp`)
      } else if (this.userGames == "RUNNER") {
        return require(`../assets/images/swiss/progress-bar.webp`)
      } else {
        return require(`../assets/images/china/progress-bar.webp`)
      }
    },
    computedUserProgress() {
      const total = '100%';
      const deduction = this.totalProsen;
      // Extract numeric values
      const totalValue = parseFloat(total);
      const deductionValue = parseFloat(deduction);
      // Perform the calculation
      const result = `${totalValue - deductionValue}%`;
      return result;
    },
    progressPercentage() {
      // Extract only the numeric percentage part
      const match = this.userProgres.match(/^\d+(\.\d+)?%/);
      return match ? match[0] : ""; // Return the matched part or an empty string if no match
    }
  },
  watch: {
    showEntertainment(newValue) {
      const gameStyles = {
        SPEED: { true: "splash-china-style", false: "china-style" },
        HUNTER: { true: "splash-dubai-style", false: "dubai-style" },
        RUNNER: { true: "splash-swiss-style", false: "swiss-style" },
      };

      if (this.userGames in gameStyles) {
        const style = gameStyles[this.userGames][newValue];
        this.$store.commit("updateAppClass", style);
      }
    },
    isPortrait(newValue) {
      if (newValue) {
        this.isTriggerButtonProfile = false;
      }
    },
    isComingSoon(newValue) {
      if (newValue) {
        this.isTriggerButtonProfile = false;
      }
    }
  },
  async created() {
    window.addEventListener("resize", this.resizeWindow);

    this.setGimmickVideo();
    this.setPromosiAwal();
    // console.log(this.videoGimmick);
    if (this.userGames == "SPEED") {
      this.isChina = true;
      this.srcSplashLogo = "china/logo.webp";
      this.srcSplashWelcome = "welcome.webp";
      this.srcProfileButton = "china/profile-button.webp";
      this.srcEntertainmentGames = "china/entertainment-games.webp";
      this.srcEntertainmentMissions = "china/entertainment-missions.webp";
      this.srcEntertainmentCheckin = "china/entertainment-checkin.webp";
      this.srcButtonTutorial = "china/faq-tutorial.webp";
      this.srcButtonProgram = "china/faq-program.webp";
      this.srcButtonBack = "china/button-back.webp";
      this.srcFAQHelpContain = "china/faq-scroll-bantuan.webp";
      this.srcButtonAllPromo = "china/promo-all-promo.webp";
      this.srcButtonUnduhPromo = "china/promo-unduh-promo.webp";
      this.srcButtonNews = "china/promo-news.webp";
      this.srcBacksound = "speed-music.mp3";
      this.classIsland = "island island-china";
      this.classFrame = "video-frame china-frame";
      this.classPopupFrame = "planetpopup-frame china-popup-frame";
      this.classChartFrame = "chart-frame china-chart-frame";
      this.classDailyFrame = "daily-frame daily-china-frame";
      this.classEntertainment = "nav-entertainment china-entertainment";
      this.classPerformance = "nav-performance china-performance";
      this.classPromo = "nav-promo china-promo";
      this.classFaq = "nav-faq china-faq";
      this.classWelcomeText = "china-welcome-text";
      this.videoTutorialOne = "talentspeed.mp4";
      this.videoTutorialTwo = "tutorialspeed.mp4";
      this.videoTutorialTwoPoster = "video-tutorial-poster-speed.png";
      this.$store.commit("updateAppClass", "china-style");
    } else if (this.userGames == "HUNTER") {
      this.isDubai = true;
      this.srcSplashLogo = "dubai/logo.webp";
      this.srcSplashWelcome = "welcome.webp";
      this.srcProfileButton = "dubai/profile-button.webp";
      this.srcEntertainmentGames = "dubai/entertainment-games.webp";
      this.srcEntertainmentMissions = "dubai/entertainment-missions.webp";
      this.srcEntertainmentCheckin = "dubai/entertainment-checkin.webp";
      this.srcButtonBack = "dubai/button-back.webp";
      this.srcButtonTutorial = "dubai/faq-tutorial.webp";
      this.srcButtonProgram = "dubai/faq-program.webp";
      this.srcFAQHelpContain = "dubai/faq-scroll-bantuan.webp";
      this.srcButtonAllPromo = "dubai/promo-all-promo.webp";
      this.srcButtonUnduhPromo = "dubai/promo-unduh-promo.webp";
      this.srcButtonNews = "dubai/promo-news.webp";
      this.srcBacksound = "hunter-music.mp3";
      this.classIsland = "island island-dubai";
      this.classFrame = "video-frame dubai-frame";
      this.classPopupFrame = "planetpopup-frame dubai-popup-frame";
      this.classChartFrame = "chart-frame dubai-chart-frame";
      this.classDailyFrame = "daily-frame daily-dubai-frame";
      this.classEntertainment = "nav-entertainment dubai-entertainment";
      this.classPerformance = "nav-performance dubai-performance";
      this.classPromo = "nav-promo dubai-promo";
      this.classFaq = "nav-faq dubai-faq";
      this.classWelcomeText = "dubai-welcome-text";
      this.videoTutorialOne = "talenthunter.mp4";
      this.videoTutorialTwo = "tutorialhunter.mp4";
      this.videoTutorialTwoPoster = "video-tutorial-poster-hunter.png";
      this.$store.commit("updateAppClass", "dubai-style");
    } else if (this.userGames == "RUNNER") {
      this.isSwiss = true;
      this.srcSplashLogo = "swiss/logo.webp";
      this.srcSplashWelcome = "welcome.webp";
      this.srcProfileButton = "swiss/profile-button.webp";
      this.srcEntertainmentGames = "swiss/entertainment-games.webp";
      this.srcEntertainmentMissions = "swiss/entertainment-missions.webp";
      this.srcEntertainmentCheckin = "swiss/entertainment-checkin.webp";
      this.srcButtonBack = "swiss/button-back.webp";
      this.srcButtonTutorial = "swiss/faq-tutorial.webp";
      this.srcButtonProgram = "swiss/faq-program.webp";
      this.srcFAQHelpContain = "swiss/faq-scroll-bantuan.webp";
      this.srcButtonAllPromo = "swiss/promo-all-promo.webp";
      this.srcButtonUnduhPromo = "swiss/promo-unduh-promo.webp";
      this.srcButtonNews = "swiss/promo-news.webp";
      this.srcBacksound = "runner-music.mp3";
      this.classIsland = "island island-swiss";
      this.classFrame = "video-frame swiss-frame";
      this.classPopupFrame = "planetpopup-frame swiss-popup-frame";
      this.classChartFrame = "chart-frame swiss-chart-frame";
      this.classDailyFrame = "daily-frame daily-swiss-frame";
      this.classEntertainment = "nav-entertainment swiss-entertainment";
      this.classPerformance = "nav-performance swiss-performance";
      this.classPromo = "nav-promo swiss-promo";
      this.classFaq = "nav-faq swiss-faq";
      this.classWelcomeText = "swiss-welcome-text";
      this.videoTutorialOne = "talentrunner.mp4";
      this.videoTutorialTwo = "tutorialrunner.mp4";
      this.videoTutorialTwoPoster = "video-tutorial-poster-runner.png";
      this.$store.commit("updateAppClass", "swiss-style");
    }

    try {
      // Menggunakan await untuk menunggu hingga pengambilan data selesai
      await this.getPlanet();
      await this.getDataAwal();
      await this.getDataChart();
      // this.showChartModal = true;
    } catch (error) {
      console.error("Inisialisasi data awal gagal -- ", error);
    }
  },
  unmount() {
    window.removeEventListener("resize", this.resizeWindow);
  },
  mounted() {
    document.title = "FUNTASTIC LEAGUE - DASHBOARD";
    const isAuthenticated = this.$store.getters.isAuthenticated;
    //const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (!isAuthenticated) {
      // Jika belum login, redirect ke awal
      this.$router.push("/");
    }
    this.classSplashLogo = "splash-logo-main scale-up-center";
    this.splashItems[0].class = "splash-cloud-left slide-left";
    this.splashItems[1].class = "splash-cloud-right slide-right";
    this.showEntertainment = false;
    this.showPerformance = true;
    this.showPromo = false;
    this.showFaq = false;
    setTimeout(() => {
      this.tranparentTextPlanet = false;
    }, 400);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "../assets/css/main.css";
</style>
